import { useCallback, useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode';
import './App.scss';

function App() {

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const [url, setURL] = useState('');
  const qrCodeCanvas = useRef(null);

  useEffect(() => {
    const checkVersion = async () => {
      const backendURL = env?.api?.host;
      const endpoint = `${backendURL}/app/checkVersion?appVersion=initial`;
      const options = { headers: { Accept: 'application/json' } };

      try {
        const response = await fetch(endpoint, options);
        const { newVersionUrl } = await response.json();

        setURL(newVersionUrl);
        QRCode.toCanvas(qrCodeCanvas.current, newVersionUrl, (error) => {
          if (error) {
            console.error(error)
          } else {
            console.log('qr code success!');
          }
        });
      } catch (error) {
        console.error(error);
      }
    };
    checkVersion();
  }, []);

  const install = useCallback(() => {
    window.location.href = url;
  }, [url]);

  return (
    <div className="App">
      <h1>Husqvarna Uptool iOS Install</h1>
      {isIOS
        ? <>
          <div>
            <p>Install the UpTool app by clicking Install.</p>
          </div>
          <div>
            <button type="button" onClick={install} className="action bold accented right">Install</button>
          </div>
        </>
        : <>
          <div>
            <p>Install the UpTool app by scanning th is QR code with your iOS device</p>
          </div>
          <canvas ref={qrCodeCanvas} />
        </>
      }
      <div>
        <p>It will appear on the home screen of your iPad/iPhone once installed.</p>
      </div>
    </div>
  );
}

export default App;
